
































































import { Component, Ref, Prop, Mixins } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import DrawerComponent from '@common-src/mixins/drawer-component';
import FormComponent from '@common-src/mixins/form-component';
import { InspectionEntityModel } from '@common-src/entity-model/inspection-entity';
import { ViewModeType } from '@common-src/model/enum';
import InspectionService from '@common-src/service/inspection';
import JTLTransferComponent from '@common-src/components/jtl-transfer/index.vue';
import { DATE_FORMAT } from '@common-src/filter/date-format';
import moment, { Moment } from 'moment';
import common from '@common-src/service/common';
import CheckListComponent from './check-list.vue';

@Component({
    components: {
        'jtl-transfer-component': JTLTransferComponent,
        'check-list-component': CheckListComponent
    }
})
export default class InspectionDetailComponent extends Mixins(BaseComponent, DrawerComponent) {
    @Prop()
    record;

    @Prop()
    viewType;

    visible: boolean =false;

    title: string = '';
    transferData: any = [];
    keys: string[] = [];
    currentFormControl: any = undefined;

    checkList: Array<{method:string, project:string, type:string, required:boolean, conclusion:boolean, result:boolean}> = [];
    ViewModeType = ViewModeType;

    /**
     * 表单组件
     */
    @Ref()
    basicForm: FormComponent<any>;

    inspectionEntityModel:InspectionEntityModel;
    service:any;

    created() {
        this.service = InspectionService;
        this.$nextTick(() => {
            if (this.record) {
                this.inspectionEntityModel = this.record;
                this.inspectionEntityModel['dateRange'] = [moment(this.inspectionEntityModel.beginDate, 'YYYY-MM-DD'), moment(this.inspectionEntityModel.endDate, 'YYYY-MM-DD')];

                if (this.inspectionEntityModel.assetIdList && this.inspectionEntityModel.assetIdList.length > 0) {
                    common.getAssetsList().then(res => {
                        this.inspectionEntityModel.assetList = res.filter(item => { return this.inspectionEntityModel.assetIdList.indexOf(item.value) >= 0; });
                        this.inspectionEntityModel.assetList = this.inspectionEntityModel.assetList.map(dt => { return { key: dt.value, title: dt.name, type: dt.type }; });

                        if (this.inspectionEntityModel.backupAssetIdList && this.inspectionEntityModel.backupAssetIdList.length > 0) {
                            common.getBackupAssetsList().then(res => {
                                this.inspectionEntityModel.backupAssetList = res.filter(item => { return this.inspectionEntityModel.backupAssetIdList.indexOf(item.value) >= 0; });
                                this.inspectionEntityModel.backupAssetList = this.inspectionEntityModel.backupAssetList.map(dt => { return { key: dt.value, title: dt.name, type: dt.type }; });
                                this.basicForm.initForm(this.inspectionEntityModel, this.viewType);
                                this.checkList = this.basicForm.formModel.checkList;
                            });
                        } else {
                            this.basicForm.initForm(this.inspectionEntityModel, this.viewType);
                            this.checkList = this.basicForm.formModel.checkList;
                        }
                    });
                } else {
                    this.basicForm.initForm(this.inspectionEntityModel, this.viewType);
                    this.checkList = this.basicForm.formModel.checkList;
                }
            } else {
                this.inspectionEntityModel = new InspectionEntityModel();
                this.basicForm.initForm(this.inspectionEntityModel, ViewModeType.NEW);
                this.checkList = [];
            }
        });
    }

    save() {
        this.basicForm.submitForm().then(res => {
            this.startFullScreenLoading();
            this.basicForm.formModel.beginDate = (_.get(this.basicForm.formModel.dateRange, '[0]') as Moment)?.startOf('day').format(DATE_FORMAT);
            this.basicForm.formModel.endDate = (_.get(this.basicForm.formModel.dateRange, '[1]') as Moment)?.startOf('day').add(1, 'day').format(DATE_FORMAT);
            delete this.basicForm.formModel.dateRange;
            this.basicForm.formModel.assetIdList = this.basicForm.formModel['assetList'].map(item => { return item.key; });
            this.basicForm.formModel.backupAssetIdList = this.basicForm.formModel['backupAssetList'].map(item => { return item.key; });
            this.basicForm.formModel.checkList = this.checkList;
            if (this.basicForm.formModel.uuid) {
                delete this.basicForm.formModel.uuid;
            }

            if (this.basicForm.formModel.createdTime) {
                delete this.basicForm.formModel.createdTime;
            }

            this.service.create(this.basicForm.formModel).then(res => {
                this.cancel();
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }

    formTableAdd(item) {
        if (item) {
            this.currentFormControl = item;
            this.title = item.label;
            if (item.dataService) {
                item.dataService().then(res => {
                    this.transferData = res.map(dt => {
                        return { key: dt.value, title: dt.name, positionName: dt.positionName };
                    });

                    this.keys = this.basicForm.formModel[this.currentFormControl.key].map(dt => { return dt.key; });
                    this.visible = true;
                });
            }
        }
    }

    handleOk() {
        this.basicForm.formModel[this.currentFormControl.key] = this.$refs['transfer']['targetData'];
        this.visible = false;
    }

    handleCancel() {
        this.visible = false;
    }

    cancel() {
        this.$emit('showDrawer');
    }

    // addCheckItem() {
    //     if (this.checkList.filter(item => { return item.project === '' && item.method === ''; }).length <= 0) {
    //         this.checkList.push({ method: '', project: '', type: 'TEXT', required: true, conclusion: false, result: true });
    //     }
    // }
    // deleteCheckItem(data) {
    //     this.checkList = this.checkList.filter(item => { return item.project !== data.project; });
    // }
}
