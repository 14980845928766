





























































import { Component, Mixins } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import TableComponent from '@common-src/mixins/table-component';
import { InspectionEntityModel, InspectionQueryModel } from '@common-src/entity-model/inspection-entity';
import InspectionService from '@common-src/service/inspection';
import InspectionDetailComponent from '@/pages/dashboard/inspection/inspection-detail.vue';
import { ViewModeType } from '@common-src/model/enum';

@Component({
    components: {
        'inspection-detail-component': InspectionDetailComponent
    }
})
export default class InspectionListComponent extends Mixins(TableComponent, DrawerComponent) {
    InspectionEntityModel = InspectionEntityModel;
    ViewModeType = ViewModeType;
    selectedRecord = '';
    viewType = ViewModeType.VIEW;

    created() {
        this.startFullScreenLoading();
        this.initTable({
            service: InspectionService,
            queryModel: new InspectionQueryModel(),
            tableColumns: InspectionEntityModel.getTableColumns()
        });
        this.getList();
        this.width = '50%';
        this.stopFullScreenLoading();
    }

    editClick(record, viewType) {
        this.selectedRecord = record;
        this.viewType = viewType;
        this.drawerOpen(record);
    }

    showDrawer() {
        this.getList();
        this.drawerVisible = false;
    }

    syncClick() {
        return InspectionService.sync().then(() => {
            this.showMessageSuccess('同步成功');
        });
    }
}
